<template>
  <span class="badge" :class="classesList">
    {{ content }}
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    content: String,
    pill: undefined,
    dim: {
      type: Boolean
    },
    outline: {
      type: Boolean
    },
    type: {
      type : String,
      required: true,
    },
  },
  setup(props){

    let classesList = [];
    if(props.outline){
      classesList.push(`badge-outline-${props.type}`)
    }
    else{
      classesList.push(`badge-${props.type}`);
    }
    props.dim ? classesList.push('badge-dim') : ''
    props.pill !== undefined ? classesList.push('badge-pill') : ''

    return {
      classesList,
    }
  }
}
</script>
