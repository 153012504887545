<template>
  <canvas class="sales-overview-chart" :id="chartId"></canvas>
</template>

<script>
import { watch } from "vue";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  Filler,
  Legend,
  Title,
  Tooltip
} from "chart.js"
Chart.register(ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    Filler,
    Legend,
    Title,
    Tooltip);

export default {
  props: {
    labels: {
      required: true,
      type: Array,
    },
    dataSet: {
      required: true,
      type: Array,
    },
    colors: {
      required: true,
      type: Array
    },
    dataUnit: {
      type: String
    },
    chartId: String,
  },
  components: {

  },
  setup(props){

    let chart = false
    watch(() => props.dataSet, () => {
      let chartItem = document.getElementById(props.chartId).getContext('2d')
      if(chart){
        chart.destroy()
      }
      chart = new Chart(chartItem, {
        type: 'line',
        data: {
          labels: props.labels,
          datasets: props.dataSet,
        },
        options: {
          plugins: {
            legend: {
              display: false,
              rtl: false,
              labels: {
                boxWidth: 30,
                padding: 20,
                fontColor: '#6783b8'
              }
            }
          },
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
            rtl: false,
            callbacks: {
              title: function title(tooltipItem, data) {
                return data['labels'][tooltipItem[0]['index']];
              },
              label: function label(tooltipItem, data) {
                return data.datasets[tooltipItem.datasetIndex]['data'][tooltipItem['index']] + ' ' /*+ _get_data.dataUnit*/;
              }
            },
            backgroundColor: '#1c2b46',
            titleFontSize: 13,
            titleFontColor: '#fff',
            titleMarginBottom: 6,
            bodyFontColor: '#fff',
            bodyFontSize: 12,
            bodySpacing: 4,
            yPadding: 10,
            xPadding: 10,
            footerMarginTop: 0,
            displayColors: false
          },
          scales: {
            yAxes: {
              stacked: false,
              position: "left",
              ticks: {
                beginAtZero: true,
                fontSize: 11,
                fontColor: '#9eaecf',
                padding: 3,
                min: 0,
                stepSize: 10
              },
              grid: {
                drawTicks: false,
                //color: 'rgba(82 100 132, 0.2)',
                //tickMarkLength: 0,
                zeroLineColor: 'rgba(82 100 132, 0.2)'
              }
            },
            xAxes: {
              stacked: false,
              ticks: {
                fontSize: 9,
                fontColor: '#9eaecf',
                source: 'auto',
                padding: 10,
                maxTicksLimit: 6,
                reverse: false,
              },
              grid: {
                display: false,
                //drawBorder: false,
                //color: "transparent",
                //tickMarkLength: 0,
                //zeroLineColor: 'transparent'
              }
            },
          }
        }
      });
    })
  },
}
</script>

